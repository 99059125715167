import React from 'react'
import Layout from "components/layout";
import Seo from "components/seo/seo";

import Thanks from "images/growth/same-day/es_thanks.png";

const ThanksYou = () => (
  <Layout className="fast-delivery thanks">
    <Seo 
      title='liftit' 
      description='make same day deliveries'
    />
    <div className="global-wrapper">
      <head>
        <h1>¡Gracias!</h1>

        <p><b>Hemos recibido tu informacion satisfactoriamente.</b> <br />Estaremos comunicados contigo.
        </p>

      </head>

      <div className="thanks-img">
        <img src={Thanks} alt="Graciass" />
      </div>

    </div>
  </Layout>
);

export default ThanksYou